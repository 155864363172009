import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { typography, space } from "styled-system";
import Layout from "../components/Layout";

import { Flex, Box } from "../components/FlexBox";
import { Paragraph } from "../components/parts/typography";
import Img from "gatsby-image";

import { graphql, Link as _Link } from "gatsby";
import Loadable from "@loadable/component";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";

const OwlCarousel = Loadable(() => import("react-owl-carousel"));

const Content = styled.div`
    > p {
        :not(:last-child) {
            margin-bottom: 20px;
        }
    }
    ${typography};
    ${space};
`;

const Title = styled.h1`
    font-weight: bold;
    ${typography};
    ${space};
`;

const SubTitle = styled.h2`
    font-size: 26px;
    font-weight: 100;
    text-transform: uppercase;
    ${typography};
    ${space};
`;

const H3 = styled.h3`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    ${typography};
    ${space};
`;

const Link = styled(_Link)`
    display: contents;
    text-decoration: none;
    color: #000;
    &[aria-current="page"] {
        img {
            opacity: 0.3;
        }
    }
`;

const GlobalStyle = createGlobalStyle`
html, body {
    display: block !important;
    flex: none !important;
  }
  #___gatsby, #gatsby-focus-wrapper {
    display: block !important;
    flex: none !important;
  }
`;

export default function subProjektTemplate({ data }) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;

    let items = data.allMarkdownRemark;

    return (
        <Layout style={{ flex: "none" }} contentTopMargin="0">
            <GlobalStyle />
            <Flex
                data-name="sub-page"
                flexDirection="column"
                mb={["150px", "220px"]}
                mx="-20px"
                width={["100%", "calc(100% + 40px)"]}
            >
                <Flex flexDirection={["column", "row"]}>
                    <Flex
                        flex="1 1 50%"
                        flexDirection="column"
                        alignItems="flex-end"
                        px={[0, "18px"]}
                    >
                        {data.markdownRemark.frontmatter.attachments.map(
                            (item, i) => {
                                return (
                                    i === 0 && (
                                        <Flex
                                            key={i}
                                            width="100%"
                                            mb="36px"
                                            display={["none", "flex"]}
                                        >
                                            <Img
                                                fluid={
                                                    item.childImageSharp.fluid
                                                }
                                                style={{ width: "100%" }}
                                                fadeIn={true}
                                                objectFit="cover"
                                            />
                                        </Flex>
                                    )
                                );
                            }
                        )}
                        <Flex
                            width="100%"
                            flexDirection="column"
                            display={["none", "flex"]}
                        >
                            {data.markdownRemark.frontmatter.attachments.map(
                                (item, i) => {
                                    return (
                                        i > 0 && (
                                            <Flex
                                                justifyContent="flex-end"
                                                mb={
                                                    data.markdownRemark
                                                        .frontmatter.attachments
                                                        .length !==
                                                        i + 1 && ["36px"]
                                                }
                                                key={i}
                                            >
                                                <Img
                                                    key={i}
                                                    fluid={
                                                        item.childImageSharp
                                                            .fluid
                                                    }
                                                    fadeIn={true}
                                                    style={{
                                                        flexGrow: 1,
                                                    }}
                                                />
                                            </Flex>
                                        )
                                    );
                                }
                            )}
                        </Flex>
                    </Flex>
                    <Flex
                        flex="1 1 50%"
                        flexDirection="column"
                        px={[0, "18px"]}
                    >
                        <Box mb={["4px", "10px"]} mt={[0, "36px"]}>
                            <Title
                                noUppercase
                                mb="0"
                                fontSize={["22px", "22px"]}
                            >
                                {frontmatter.title}
                            </Title>
                        </Box>
                        <SubTitle fontSize={["14px", "18px"]}>
                            {frontmatter.subtitle}
                        </SubTitle>

                        {data.markdownRemark.frontmatter.attachments.map(
                            (item, i) => {
                                return (
                                    i === 0 && (
                                        <Flex
                                            key={i}
                                            width="100%"
                                            mb="20px"
                                            mt="20px"
                                            display={["flex", "none"]}
                                        >
                                            <Img
                                                fluid={
                                                    item.childImageSharp.fluid
                                                }
                                                style={{ width: "100%" }}
                                                fadeIn={true}
                                                objectFit="cover"
                                            />
                                        </Flex>
                                    )
                                );
                            }
                        )}

                        <Flex maxWidth={["100%", "418px"]}>
                            <Content
                                mt={[0, "36px"]}
                                mb={["64px", "60px"]}
                                fontSize={["15px"]}
                                fontWeight={["400", "300"]}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </Flex>
                        <Box mb={["36px"]}>
                            <H3>Unsere Leistungen</H3>
                            <Paragraph>{frontmatter.abstract}</Paragraph>
                        </Box>
                        <Box mb={["64px"]}>
                            <H3>Auftraggeber</H3>
                            <Paragraph>{frontmatter.participants}</Paragraph>
                        </Box>
                    </Flex>
                </Flex>
                <Flex
                    width="100%"
                    flexDirection="column"
                    display={["flex", "none"]}
                    px={[0, "18px"]}
                >
                    {data.markdownRemark.frontmatter.attachments.map(
                        (item, i) => {
                            return (
                                i > 0 && (
                                    <Flex
                                        key={i}
                                        justifyContent="flex-end"
                                        mb={
                                            data.markdownRemark.frontmatter
                                                .attachments.length !==
                                                i + 1 && ["26px"]
                                        }
                                    >
                                        <Img
                                            key={i}
                                            fluid={item.childImageSharp.fluid}
                                            fadeIn={true}
                                            style={{ flexGrow: 1 }}
                                        />
                                    </Flex>
                                )
                            );
                        }
                    )}
                </Flex>
            </Flex>
            <Box flex="none" width="100%">
                <Carousel projects={items.edges} frontmatter={frontmatter} />
            </Box>
        </Layout>
    );
}

export function Carousel({ projects, frontmatter }) {
    const breakpoints = {
        responsive: {
            0: {
                items: 1,
            },
            375: {
                items: 1,
            },
            728: {
                items: 2,
            },
            900: {
                items: 3,
            },
            1023: {
                items: 3,
            },
        },
    };
    return (
        <OwlCarousel
            className="owl-theme"
            margin={20}
            nav
            items={3}
            responsive={breakpoints.responsive}
            dots={false}
            navText={[
                "<div class='nav-btn prev-slide' />",
                "<div class='nav-btn next-slide' />",
            ]}
        >
            {projects.map((item, i) => {
                let content = item.node.frontmatter;
                return (
                    content.published &&
                    frontmatter.path !== content.path && (
                        <Link key={i} to={content.path}>
                            <Img
                                fadeIn={true}
                                fluid={
                                    content.thumbnailImage.childImageSharp.fluid
                                }
                            />
                            <SubTitle fontSize="12px" fontWeight="400" mt="6px">
                                {content.title}
                            </SubTitle>
                        </Link>
                    )
                );
            })}
        </OwlCarousel>
    );
}

export const subPageQuery = () => {
    graphql`
        query ($path: String!) {
            markdownRemark(frontmatter: { path: { eq: $path } }) {
                html
                frontmatter {
                    published
                    path
                    title
                    subtitle
                    abstract
                    period
                    design
                    participants
                    attachments {
                        relativePath
                        publicURL
                        childImageSharp {
                            fluid(
                                quality: 100
                                maxWidth: 700
                                srcSetBreakpoints: [
                                    375
                                    500
                                    600
                                    800
                                    900
                                    1023
                                    1280
                                    1920
                                ]
                            ) {
                                ...GatsbyImageSharpFluid
                                ...GatsbyImageSharpFluidLimitPresentationSize
                            }
                        }
                    }
                    thumbnailImage {
                        childImageSharp {
                            fluid(
                                quality: 50
                                maxWidth: 700
                                srcSetBreakpoints: [
                                    375
                                    500
                                    600
                                    800
                                    900
                                    1023
                                    1280
                                    1920
                                ]
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                            path
                            title
                            published
                            thumbnailImage {
                                childImageSharp {
                                    fluid(
                                        quality: 50
                                        maxWidth: 700
                                        srcSetBreakpoints: [
                                            375
                                            800
                                            1280
                                            1920
                                        ]
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
};
